import { PflegeTracking } from '@shared'

const { toSystemFriendlyString } = usePflegeUtils()

let useTrackingClassInstance = null
const etData = ref(null)

export default function useTracking() {
  const init = (window, document, gtmKey, initData = {}) => {
    if (window && document && gtmKey) {
      if (!useTrackingClassInstance) {
        useTrackingClassInstance = new PflegeTracking()
      }
      useTrackingClassInstance.initGTM(window, document, gtmKey)
      provide('track', (trackingEvent, trackingLabel) => {
        useTrackingClassInstance.trackDatalayer({
          event: trackingEvent,
          label: trackingLabel
        })
      })

      useTrackingClassInstance.addData(initData)
    }
  }

  const initET = async (productName, versionNumber = null) => {
    if (!useTrackingClassInstance) {
      useTrackingClassInstance = new PflegeTracking()
    }
    await useTrackingClassInstance.initET(
      productName,
      versionNumber,
      useRuntimeConfig().public.etUrl || undefined
    )
    etData.value = useTrackingClassInstance.etData
  }

  const trackDatalayer = (data) => {
    if (useTrackingClassInstance) {
      useTrackingClassInstance.trackDatalayer(data)
    }
  }

  const addData = (data) => {
    if (useTrackingClassInstance) {
      useTrackingClassInstance.addData(data)
    }
  }

  const trackClick = (component, mainComponent, label, additionalData = null) => {
    const eventData = {
      event: 'gaEvent',
      event_name: 'click',
      click: {
        component,
        main_component: mainComponent,
        label
      }
    }
    if (additionalData !== null) {
      Object.assign(eventData.click, additionalData)
    }
    provide('track', () => {
      useTrackingClassInstance.trackDatalayer(eventData)
    })
  }

  const createTrackingLabel = (label) => {
    return toSystemFriendlyString(label)
  }

  return {
    init,
    initET,
    trackDatalayer,
    addData,
    trackClick,
    createTrackingLabel,
    etData
  }
}
